.meTpTtlPower{
    width: 100%;
    font-weight: bolder;
    font-size: 20px;
    padding: 20px;
    
    width: 250px;
    line-height: 35px;
    border-radius: 15px;
    
    margin: 0 auto;
    margin-top: 20px;
    text-align: center;

}

.meMeTableMinNone{
    width: 100%;
    border-collapse: collapse;
}
.meMetableMarList{
    margin-top: 20px;
}

.powerListRap{
    width: 100%;
    max-width: 880px;
    margin: 0 auto;
    margin-top: 20px;
    border-radius: 5px;
}
.powerListLiRap{
    cursor: pointer;
    width: 100%;
    padding: 20px;
    padding-left: 0px;
    padding-right: 0px;
    border-bottom: 1px solid #ced4da;
}
.powerListLiTpMar{
    margin-top: 10px !important;
}
.votingTypeBtn{
    text-align: center;
    height: 35px;
    line-height: 30px;
    border-radius: 2.5px;
    border : 1px solid #000000;
    width: 110px;
}
.powerListLi{
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    float: left;
}
.powerListRtRap{
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 20px;
    font-size: 14px;
    float: right;
}
.powerListLiBorder {
    font-weight: bold;
}
.powerListLiLtPadding{
    padding-left: 20px;
}
.eclipseTxt{
    background-color: #00FF00;
    white-space: nowrap; /* 텍스트를 한 줄로 만들기 */
    overflow: hidden; /* 넘치는 텍스트는 보이지 않도록 */
    text-overflow: ellipsis; /* 넘치는 텍스트를 ...로 표시 */

}
.agvinsertBtn{
    width: 100px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    border : 1px solid #000000;
    border-radius: 5px;
    cursor: pointer;
}