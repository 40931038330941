.votingRageInputRap{
    width: 100%;
    height: 40px;
    margin-top: 10px;
}
.votingRageInputRap > input{
    width: 100%;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
}
.votingRageTextArea {
    width: 100%;
    height: 150px;

}
.votingRageTextArea > textarea{
    width: 100%;
    height: 100%;
    padding: 10px;
}
.react-calendar{
    width: 100% !important;

}
.votingRageSubCalander{
    width: 100%;
    height: 40px;
}
.date-input {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    height: 40px;
}
.votingDateLtMar{
    margin-left: 10px;
}

.konetViewRap{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.konetViewLi{
    width: 100%;
    text-align: left;
    margin-top: 10px;
}
.konetViewLiSub{
    width: 100%;
    text-align: left;
}