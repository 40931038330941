.votingDateCall{
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    margin-top: 20px;
}
.votingDateLt{
    float: left;
}
.votingDateRt{
    float: right;
}
.MyOldCnt{
    display:inline-block;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    line-height: 25px;
    border-radius: 5px;

}
.votingContentBtn{
    float: right;
    width: 100px;
    height: 40px;
    line-height: 35px;
    border-radius: 5px;
    text-align: center;
    border : 1px solid #000000;
    
}
.votingContentDate{
    margin-top: 10px;
    font-size: 14px;
}
.votingRageRap{
    width: 100%;
    margin-top: 40px;
    padding: 20px;
    border-radius: 5px;

}
.votingRageTtl{
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}
.votingRageSub{
    margin-top: 20px;
    width: 100%;

}
.votingRageLt{
    float: left;
}
.votingPer{
    float: right;
}
.progressTpMar{
    margin-top: 0px;
}

.ledprogress {
    width: 100%;
    height: 5px;
    appearance: none;
    border: none;
    border-radius: 5px;
    background-color: #f1f1f1;
}
.ledprogress::-webkit-progress-bar {
    background-color: #f1f1f1;
    border-radius: 5px;
}
.ledprogress::-webkit-progress-value {
    background-color: red;
    border-radius: 5px;
}
.ledprogress::-moz-progress-bar {
    background-color: red;
    border-radius: 5px;
}


.greenprogress {
    width: 100%;
    height: 5px;
    appearance: none;
    border: none;
    border-radius: 5px;
    background-color: #f1f1f1;
}
.greenprogress::-webkit-progress-bar {
    background-color: #f1f1f1;
    border-radius: 5px;
}
.greenprogress::-webkit-progress-value {
    background-color: green;
    border-radius: 5px;
}
.greenprogress::-moz-progress-bar {
    background-color: green;
    border-radius: 5px;
}

.votingRageSubTtl{

}

.votingRageSubContent{
    margin-top: 20px;

}
.botingBtLine{
    margin-top: 20px;
    width: 100%;
    height: 1px;
}
.botingContentRap{
    width: 100%;
    margin-top: 20px;
}
.botingContentLiRap{
    width: 100%;
}
.botingContentLiLt{
    font-size: 14px;
    float: left;
}
.botingContentLiRt{
    font-size: 14px;
    float: right;
}

.botingTpMar{
    margin-top: 5px;
}
.votingViewRap{
    width: 100%;
    margin-top: 20px;
}
.votingBtnRap{
    width: 100%;
    height: 45px;

}
.votingBtnLt{
    width: calc(50% - 10px);
    float: left;
    height: 100%;
    text-align: center;
    line-height: 45px;
    border : 1px solid #FFFFFF;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
}
.votingBtnRt{
    margin-left: 20px;
    width: calc(50% - 10px);
    float: left;
    height: 100%;
    text-align: center;
    line-height: 45px;
    border : 1px solid #FFFFFF;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
}
.votingInputRap{
    margin-top: 20px;
    width: 100%;
    height: 45px;
}
.votingInputRap > input {
    width: 100%;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 18px;
    text-align: right;
}
.votingActive{
    border: 1px solid #000000;
}
.votingInputTxt{
    width: 100%;
    margin-top: 10px;
    text-align: right;
}
.totVotingCnt{
    margin-bottom: 20px;
    text-align: center;

}

.noneVotingDate{
    text-align: center;
    margin-top: 20px;
}
