.fc_92A6FF{
    color: #92A6FF;
}
.fc_FFFFFF{
    color: #FFFFFF;
}
.fc_0029E2{
    color: #0029E2;
}
.fc_A6A6A6{
    color: #A6A6A6;
}
.fc_00FF33{
    color: #00FF33;
}
.fc_0029E2{
    color: #0029E2;
}
.fc_000000{
    color: #000000;
}
