body{
background-color: #e9ecef;

}
.HomeLst{
    width: 100%;
}
.bottomHeiRap{
    width: 100%;
    height: 100px;

}

.maxWidthRap{
    width: 100%;
    max-width: 1380px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
}
.tpMarHei{
    margin-top: 50px;
}

.konetStakingTpLtRap{
    width: 500px;
    /* background-color: #00FF00; */
    float: left;
    margin-top: 100px;
    padding-right: 40px;
}
.konetStakingTpLtRap > img{
    width: 100%;
}
.konetStakingTpRtRap{
    width: calc(100% - 500px);
    float: left;
}
.ksTpRtTotRap{
    width: 100%;
}
.ksTpRtTotTtl{
    font-size: 32px;
    font-weight: bold;
}
.ksTpRtTotSubTtl{
    margin-top: 20px;
    font-size: 18px;
    line-height: 25px;
}
.ksTpRtTotRap{
    width: 100%;
    padding: 20px;
    margin-top: 30px;
    border-radius: 20px;
}

.kstrt_Ttl{
    width: 100%;
    font-size: 18px;
    font-weight: bold;
}
.kstrt_Sub{
    width: 100%;
    margin-top: 10px;
    height: 50px;
    border : 1px solid #ced4da;
    border-radius: 10px;
}
.kstrtInput{
    width: 100%;
    border-radius: 10px;
    height: 100%;
    background-color: transparent;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;

}
.kstrt_Ttl_tp_mar{
    margin-top: 20px;
}
.kstrt_per{
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 50px;
    font-size: 18px;
}

.stakingButton{
    width: 100%;
    height: 55px;
    text-align: center;
    margin-top: 30px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    line-height: 55px;
}

.meMarHei{
    margin-top: 50px;
}

.meTpTtl{
    width: 100%;
    font-weight: bolder;
    font-size: 38px;
}

.meMeRap{
    overflow: auto;
    width: 100%;
    border-radius: 10px;
    
}
.meMetableMar{
    margin-top: 50px;

}
.meMeTable{
    min-width: 1340px;
    border-collapse: collapse;
}

.meMeTableTd{
    border-bottom: 1px solid #ced4da;
    text-align: center;
    font-size: 18px;
    padding: 20px;
}
.meMeTableTdBorder{
    font-weight: bold;
}
.meMeTableTdAprBtn{
    width: 100%;
    height: 55px;
    line-height: 55px;
    text-align: center;
    border-radius: 10px;
    border : 1px solid #324C7B;
    font-size: 20px;
    font-weight: bold;
}

ul,li {margin:0; padding:0;}

.meTxtRap{
    margin-top: 20px;
    width: 100%;
}
.jumFontResize{
    padding-right: 10px;
    font-size: 22px;
}
.bonTxtCall{
    line-height: 25px;
    font-size: 18px;
}
.meTwoMar{
    margin-top: 100px;
}
.tokenSymbolLt{
    float: left;
}
.tokenSymbolRap{
    display:inline-block;
}
.tokenTxtRt{
    float: left;
    margin-left: 10px;
    margin-top: 8px;
}
.meMeTableTdTwo{
    padding: 20px;
    border-bottom: 1px solid #EAECF0;
    text-align: center;
    font-size: 18px;

}
.myStakeView{
    width: 100%;
    max-width: 600px;
    border-radius: 20px;
    margin: 0 auto;
    margin-top: 20px;
    padding: 20px;
}
.myStakeViewTtlRap{
    text-align: center;
}
.myStakeViewTtlTpMar{
    margin-top: 20px;
}

.withDrawBack{
    display: none;
    position: fixed;
    z-index: 99998;
    top : 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}
.withDrawBody{
    display: none;
    position: fixed;
    z-index: 99999;
    top : calc(50% - 150px);
    left: calc(50% - 150px);
    width: 300px;

    height: 300px;
    padding: 20px;
    border-radius: 10px;
    background-color: #FFFFFF;
}

.withDrawTtl{
    width: 100%;
    font-size: 24px;
    text-align: left;
    font-weight: bold;
}

.withDrawSubTtl{
    text-align: center;
    font-size: 16px;
    margin-top: 50px;
}
.withDrawBal{
    text-align: center;
    font-size: 20px;
    margin-top: 10px;

}
.withDrawBalBorder{
    font-weight: bold;
}
.inpRap{
    width: 100%;
    height: 40px;
}
.inpRap > input{
    width: 100%;
    height: 40px;
    text-align: right;

    padding-left: 10px;
    padding-right: 10px;
}
.widthInpHei{
    width: 100%;
    height: 30px;
}
.withBtnRap{
    width: 100%;
    height: 40px;
    margin-top: 20px;
}
.withLtBtn{
    width: calc(50% - 10px);
    height: 100%;
    border : 1px solid #000000;
    float: left;
    border-radius: 10px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
}
.withRtBtn{
    width: calc(50% - 10px);
    height: 100%;
    margin-left: 20px;
    float: left;
    border-radius: 10px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
}

@media (max-width: 900px) {
    .konetStakingTpLtRap{
        float: none;
        max-width: 480px;
        width: 100%;
        margin: 0 auto;
        margin-top: 0px;
        display: none;
    }

    .konetStakingTpRtRap{
        width: 100%;
        float:none;
        margin-top: 20px;
    }

}