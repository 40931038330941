.nbg_top_black{
    background-color: rgba(0, 0, 0, 0.9);
    
}
.bg_black_000000{
    background-color: #000000;
}
.bg_black_1E293B{
    background-color: #1E293B;
}
.bg_black_090A0C {
    background-color: #090A0C;
}
.bg_blue_92A6FF{
    background-color: #92A6FF;
}
.bg_blue_5B6BAF{
    background-color: #5B6BAF;
}


.bg_blue_FFFFFF{
    background-color: #FFFFFF;
}
.bg_blue_0b1830{
    background-color: #0b1830;
}
.bg_blue_C4CFFF{
    background-color: #C4CFFF;
}
.bg_gray_e9ecef{
    background-color: #e9ecef;
}
