@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

* { 
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Apple SD Gothic Neo", "Pretendard Variable", Pretendard, Roboto, "Noto Sans KR", "Segoe UI", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
 
}

/* Chrome, Safari, Edge */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;

}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}


a { text-decoration: none; color: black; }    a:visited { text-decoration: none; }    a:hover { text-decoration: none; }    a:focus { text-decoration: none; }    a:hover, a:active { text-decoration: none; }






.lodingBarBack{
  position: fixed;
  z-index: 99999999999999999998;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.3);
  left: 0px;
  top: 0px;
  display: none;

}
.lodingBar{
  position: fixed;
  z-index: 99999999999999999999;
  width: 150px;
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  left: calc(50% - 75px);
  top: calc(50% - 50px);
}

.chromeAlert{
  display: none;
  position: fixed;
  z-index: 99999999999999999999;
  width: 300px;
  text-align: center;
  padding: 20px;

  background-color: #FFFFFF;

  border-radius: 5px;
  left: calc(50% - 75px);
  top: calc(50% - 50px);

}
.chromBtn{
  margin-top: 30px;
  display: inline-block;
  background-color: #000000;
  padding: 10px;
  border-radius: 5px;
  color: #FFFFFF;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  
}


.lodingBar > img{
  width: 100%;
}
.bottomVersion{
  width: 100%;
  text-align: center;
  padding: 30px;
  color: #adb5bd;

}