.topMenuMarHei{
    width: 100%;
    height: 70px;
}
.topMenuLtMobileRap{
    float: left;

}
.topmenuTotRap{
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.topMenuRap{
    z-index: 999;
    position: fixed;
    height: 70px;
    left: 0px;
    top: 0px;
    width: 100%;
}

.topMenuLtRap{
    float: left;
}
.topMenuLtLogo{
    width: 175px;
    float: left;
}
.topMenuLtLogo > img{
    width: 100%;
}
.topMenuLtTxt{
    float: left;
    margin-top: 12.5px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}
.txtLtMar{
    margin-left: 40px;
}
.txtLtTwoMar{
    margin-left: 30px;
}

.topMenuRtIconTxtTotLoginRap{
    display: none;
    float: left;
}
.topMenuRtIconTxtTotNotLoginRap{
    float: left;
}
.layPopAddress{
    display: none;
    position: absolute;
    z-index: 999;
    width: 200px;
    height: 100px;
    top : 40px;
    right: 30px;
    border-radius: 5px;
    background-color: #FFFFFF;
    border : 1px solid #ced4da;
}
.closeName{
    width: 100%;
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}

.topMenuRtRap{
    position: relative;
    float: right;
}

.topMenuRtIconTxtRap{
    float: left;
    margin-top: 12.5px;
}
.topMenuRtIconTxt{
    float: left;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    margin-left: 10px;
}

.topMenuRtIcon{
    float: left;
    cursor: pointer;
    margin-top: 1px;

}
.claimButton{
    float: left;
    margin-left: 30px;
    width: 120px;
    height: 40px;
    border-radius: 999999px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    font-size: 20px;
}
.connetWalletButton{
    float: left;
    padding-left: 20px;
    padding-right: 20px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    margin-left: 30px;
    margin-top: 5.5px;
    border-radius: 9999px;
    cursor: pointer;
}

.connetWalletButtonPop{
    width: 170px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    margin-top: 20px;
    border-radius: 9999px;
    cursor: pointer;
}
.walletInfoButton{
    display: none;
    float: left;
    width: 25px;
    cursor: pointer;
    margin-left: 20px;
}
.walletInfoLi{
    width: 100%;
    height: 2px;
    margin-top: 6.5px;
    
}
.mobileWalletIcon{
    float: left;
    margin-left: 20px;
    margin-top: 5px;
    display: none;
}

.subMenuDivRap{
    border-top: 1px solid #ced4da;
    position: fixed;
    top: 60px;
    left: 0px;
    display: none;
    width: 100%;
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
}
.subMenuRap{
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-bottom: 1px solid #5F5F5F;
}
.subMenuLi{
    float: left;
    height: 100%;
    display:inline-block;
    padding-left: 15px;
    padding-right: 15px;
    line-height: 45px;
    font-weight: bold;
    color: #5F5F5F;
}
.subMenuActive{
    border-bottom: 1px solid #000AFF;
    color: #000000;
}

.netWorkAddCehck{
    display: none;
    position: fixed;
    width: 100%;
    height: 50px;
    left : 0px;
    z-index: 999;
    top : 70px;
    background-color: #DB4455;
    margin: 0 auto;
    border-radius: 5px;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    color: #FFFFFF;
}




@media (max-width: 1400px) {
    .netWorkAddCehck{
        top: 60px;
    }
    .mobileWalletIcon{
        display: block;
    }
    .topMenuRtIconTxtTotLoginRap{
        display: none;
    }
    .topMenuRtIconTxtTotNotLoginRap{
        display: none;
    }
    .centerLt{
        float: none;
        width: 100%;
        text-align: center;
    }
    .centerRt{
        float: none;
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        text-align: center;
    }
    .centerLst{
        width: 100%;
    }
    .walletInfoButton{
        display: block;
    }
    .topMenuRap{
        height: 60px;
    }
    .topMenuLtTxt{
        margin-top: 5px;
    }
    .topMenuMarHei{
        height: 60px;
    }
    .topMenuLtLogo{
        width: 120px;

    }
    .topMenuLtLogo > img{
        width: 100%;
    }
}
@media (max-width: 750px) {
    
    .topMenuLtMobileRap{
        display: none;
    }

}

@media (max-width: 600px) {
    .mobileEx{
        font-size: 14px;
        margin-top: 6.5px;
    }

    .centerLtTpTxt{
        
        font-size: 26px;
        line-height: 26px;
        margin-top: 20px;
    }
    .centerLTSubTxt{
        font-size: 17px;
        line-height: 17px;
    }
    .walletInfoButton{
        display: block;
    }


}